<template>
  <div class="w-full max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
    <h2 class="text-3xl font-bold text-center mb-6">Расчет паттерна</h2>

    <!-- Palette Selection -->
    <div class="mb-6">
      <label for="palette" class="block text-sm font-medium text-gray-700 mb-2">
        Выберите Способ
      </label>
      <div class="relative">
        <select
          v-model="palette"
          id="palette"
          class="block w-full rounded-lg border border-gray-300 bg-white px-4 py-3 pr-8 focus:border-indigo-500 focus:ring-indigo-500 appearance-none"
        >
          <option value="9">9 цветов</option>
          <option value="12">12 цветов</option>
          <option value="13">13 цветов</option>
        </select>
        <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-3 text-gray-700">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
          </svg>
        </div>
      </div>
    </div>

    <!-- Wish Phrase Input -->
    <div class="mb-6">
      <label for="wishphrase" class="block text-sm font-medium text-gray-700 mb-2">
        Намерение
      </label>
      <div class="flex">
        <input
          type="text"
          id="wishphrase"
          v-model="payload"
          @input="resetForm"
          placeholder="Введите ваше намерение"
          class="flex-1 rounded-l-lg border border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 px-4 py-3"
        />
        <button
          @click="submitForm()"
          :disabled="btnIsDisable"
          class="bg-indigo-600 text-white px-6 py-3 rounded-r-lg hover:bg-indigo-700 transition-colors duration-150 disabled:opacity-50 disabled:cursor-not-allowed"
        >
          Сформировать
        </button>
      </div>
    </div>

    <!-- Results Section -->
    <div v-if="isCalculated" class="mb-6">
      <div class="flex justify-between items-end mb-2">
        <label class="block text-sm font-medium text-gray-700">
          Результаты расчета
        </label>

        <!-- Scroll notice - always visible -->
        <div class="text-sm text-gray-500 flex items-center">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
          </svg>
          Используйте внизу и справа прокрутку, если намерение длинное
        </div>
      </div>

      <!-- Standard scrollable container with fixed height and visible scrollbars -->
      <div class="overflow-auto rounded-lg bg-white p-3 border border-gray-200 pattern-container" style="max-height: calc(100vh - 500px);">
        <table class="min-w-full border-separate" style="border-spacing: 4px;">
          <thead>
            <tr>
              <th v-for="(char, index) in charArray" :key="`char-${index}`"
                  class="w-9 h-9 bg-gray-50 border border-gray-200 rounded text-center text-sm font-normal">
                {{ char }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(row, rowIndex) in formattedResult" :key="`row-${rowIndex}`">
              <td v-for="(cell, cellIndex) in row" :key="`cell-${rowIndex}-${cellIndex}`"
                  class="w-9 h-9 border border-gray-200 rounded bg-white text-center text-sm">
                {{ cell }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <!-- Export Button -->
      <div class="flex justify-center mt-6">
        <button
          @click="exportToCSV"
          class="bg-indigo-600 text-white px-6 py-3 rounded-lg hover:bg-indigo-700 transition-colors duration-150 inline-flex items-center"
        >
          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
          </svg>
          Выгрузить в CSV
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { useAuthStore } from "@/stores/user";
import { inject, ref, computed } from 'vue'

export default {
  name: "PatternCalculator",
  props: {
    embedded: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const userStore = useAuthStore();
    const payloadRegex = inject('payloadRegex', /./);

    const payload = ref("");
    const result = ref([]);
    const palette = ref("12");
    const isCalculated = ref(false);

    const API_URL = typeof import.meta.env.VITE_ROOTAPI === "undefined"
      ? "http://service:8000"
      : import.meta.env.VITE_ROOTAPI;

    const btnIsDisable = computed(() => {
      return !payload.value || !palette.value;
    });

    // Track the valid characters from the input
    const charArray = computed(() => {
      return payload.value.split('').filter(char => payloadRegex.test(char));
    });

    // Format the result for display
    const formattedResult = computed(() => {
      if (!result.value || !result.value.length) return [];
      return result.value;
    });

    const submitForm = async () => {
      try {
        const response = await axios.get(`${API_URL}/draw/api/v1/free/democalc/`, {
          params: { q: payload.value }
        });
        result.value = response.data['main_matrix'][palette.value];
        isCalculated.value = true;
      } catch (error) {
        console.error("Error calculating pattern:", error);
      }
    };

    const resetForm = () => {
      result.value = [];
      isCalculated.value = false;
    };

    const exportToCSV = () => {
      // Creating CSV content
      const headers = charArray.value.join(',');
      const rows = formattedResult.value.map(row => row.join(','));
      const csvContent = [headers, ...rows].join('\n');

      // Create a Blob and download
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement('a');

      link.setAttribute('href', URL.createObjectURL(blob));
      link.setAttribute('download', 'паттерн_мандала.csv');

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    };

    return {
      payload,
      result,
      palette,
      isCalculated,
      btnIsDisable,
      charArray,
      formattedResult,
      payloadRegex,
      userStore,
      submitForm,
      resetForm,
      exportToCSV
    };
  }
};
</script>

<style scoped>
/* Make scrollbars more visible */
.pattern-container {
  /* Thicker scrollbars */
  scrollbar-width: auto;
  scrollbar-color: #6366f1 #f3f4f6;
}

.pattern-container::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

.pattern-container::-webkit-scrollbar-track {
  background: #f3f4f6;
  border-radius: 6px;
  border: 1px solid #e5e7eb;
}

.pattern-container::-webkit-scrollbar-thumb {
  background-color: #6366f1;
  border-radius: 6px;
  border: 2px solid #f3f4f6;
}

/* Ensures consistent cell sizing */
.pattern-container td,
.pattern-container th {
  min-width: 36px;
  width: 36px;
  height: 36px;
}
</style>
