<script>
import axiosInstance from '@/axios/axios';
import { inject, ref, onMounted } from 'vue';
// Update your imports to just use Dialog
import { ExclamationTriangleIcon, InformationCircleIcon, PrinterIcon, QuestionMarkCircleIcon } from '@heroicons/vue/24/outline'

function encodeQueryData(data) {
  const ret = [];
  for (let d in data)
    ret.push(encodeURIComponent(d) + "=" + encodeURIComponent(data[d]));
  return ret.join("&");
}

const API_URL =
  typeof import.meta.env.VITE_ROOTAPI === "undefined"
    ? "http://service:8000"
    : import.meta.env.VITE_ROOTAPI;

export default {
  name: "Wish-Phrase",
  setup() {
    const payloadRegex = inject('payloadRegex');
    const payloadRegexG = inject('payloadRegexG');
    const showHelpModal = ref(false);
    const userInfo = ref(null);

    onMounted(async () => {
      try {
        const response = await axiosInstance.get(`${API_URL}/api/auth/userinfo/`);
        userInfo.value = response.data;
      } catch (error) {
        console.error('Failed to fetch user info:', error);
      }
    });

    return {
      payloadRegex,
      payloadRegexG,
      showHelpModal,
      userInfo
    };
  },
  components: {
    ExclamationTriangleIcon,
    InformationCircleIcon,
    PrinterIcon,
    QuestionMarkCircleIcon
  },
  data() {
    return {
      commondata: { menu: { shapes: {} } },
      b64image: "",
      steps: {
        1: true,
        2: false,
        3: false,
      },
      payload: "",
      patternsList: 0, //Lists of available patterns
      shapeSelected: "",
      shapeSelectedId: "",
      patternSymbols: "",
      patternLenSelected: 1,
      paletteSelected: 9,
      firstMethodSelected: "",
      additionalMethodSelected: "",
      main_matrix: { 1: [1] },
      patternValue: "%",
      pattern: "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20",
      patternUnfiltered: "",
      printDefaultMandala: "true",
      printOnlyDigits: false,
      printOnlyDots: "false",
      printDotsByNumbers: "false",
      mandalaForm: "colorized",
      sectorLength: 8,
      open: false,
      print_open: false,
      currentStep: 1,
      showErrorModal: false,
      errorMessage: "",
      isLoading: false,
    };
  },
  computed: {
    computedLocale() {
      return this.$i18n.locale.trim();
    },
    fakePayload() {
      return this.payload.replace(!this.payloadRegex, '');
    },
    baseUrl() {
      return window.location.origin + this.$route.path;
    },
    firstMethod: function () {
      if (this.commondata["methods"]) {
        return this.commondata["methods"];
      } else {
        return "";
      }
    },
    additionalMethod: function () {
      if (this.commondata["methods"]) {
        var methods = this.commondata["methods"];
        return methods[this.firstMethodSelected].additional_methods;
      } else {
        return {};
      }
    },
    firstMethodLength: function () {
      if (this.commondata["methods"]) {
        var methods = this.commondata["methods"];
        if (Object.keys(methods).length) {
          if (Object.keys(methods[this.firstMethodSelected]).length) {
            return Object.keys(methods[this.firstMethodSelected]).length;
          } else {
            return 0;
          }
        } else {
          return 0;
        }
      } else {
        return 0;
      }
    },
    additionalMethodLength: function () {
      if (this.commondata["methods"]) {
        var methods = this.commondata["methods"];
        if (Object.keys(methods).length) {
          if (methods[this.firstMethodSelected].additional_methods) {
            return Object.keys(
              methods[this.firstMethodSelected].additional_methods
            ).length;
          } else {
            return 0;
          }
        } else {
          return 0;
        }
      } else {
        return 0;
      }
    },
    paletteRendered: function () {
      var result = {};
      if (this.patternSymbols) {
        var pattern_first_symbol =
          this.patternSymbols[0] < 2 ? 1 : this.patternSymbols[0];
        var temp_palette =
          this.commondata["palettes"][this.paletteSelected][
          pattern_first_symbol
          ];
        result[1] = {};
        result[2] = {};
        for (let i = 1; i <= 7; i++) {
          result[1][i] = {};
          result[1][i]["id"] = i;
          result[1][i]["hex"] = temp_palette[i];
        }
        for (let i = 8; i <= this.paletteSelected; i++) {
          result[2][i] = {};
          result[2][i]["id"] = i;
          result[2][i]["hex"] = temp_palette[i];
        }
        return result;
      }
      return result;
    },
    mainMatrixRendered: function () {
      let result = [];
      if (Object.keys(this.main_matrix).length > 1) {
        if (this.main_matrix[this.paletteSelected].length > 0) {
          for (
            let i = 0;
            i < this.main_matrix[this.paletteSelected].length;
            i++
          ) {
            let row = [];
            if (Array.isArray(this.main_matrix[this.paletteSelected][i])) {
              for (
                let j = 0;
                j < this.main_matrix[this.paletteSelected].length;
                j++
              ) {
                if (j < this.main_matrix[this.paletteSelected][i].length) {
                  row[j] = this.main_matrix[this.paletteSelected][i][j];
                } else {
                  row[j] = "";
                }
              }
              result[i] = row;
            }
          }
          return result;
        } else {
          return result;
        }
      } else {
        return result;
      }
    },
    payloadFactLength: function () {
      let symbolRegExp = "";
      symbolRegExp = this.payloadRegexG ? this.payloadRegexG : /[0-9a-zA-Zа-яА-ЯЁё_]/g;
      const matches = Array.from(this.payload.matchAll(symbolRegExp));
      const count = matches.length;
      console.log(`The number of matches for '${symbolRegExp}' is: ${count}`);
      return count || 0;
    },
    showSectorLength: function () {
      return ["eight_diamond", "six_diamond", "stern"].includes(this.shapeSelected) && this.firstMethodSelected[0] == '8'
    },
    remainingMandalaDrawings: function () {
      if (this.userInfo && this.userInfo.mandala_drawings) {
        return this.userInfo.mandala_drawings.remaining;
      }
      return null;
    },
    totalMandalaDrawings: function () {
      if (this.userInfo && this.userInfo.mandala_drawings) {
        return this.userInfo.mandala_drawings.allowed;
      }
      return null;
    }
  },
  beforeCreate() {
    axiosInstance
      .get(`${API_URL}/draw/api/v1/free/commondata/`)
      .then((response) => (this.commondata = response.data));
  },

  methods: {

    isColorDark(hexColor) {
      if (!hexColor || !hexColor.startsWith('#') || hexColor.length !== 7) {
        return false;
      }

      // Convert hex to RGB
      const r = parseInt(hexColor.slice(1, 3), 16);
      const g = parseInt(hexColor.slice(3, 5), 16);
      const b = parseInt(hexColor.slice(5, 7), 16);

      // Calculate luminance
      const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

      // Return true if color is dark (luminance < 0.5)
      return luminance < 0.5;
    },
    gotoStep: function (step) {
      this.currentStep = step;
      for (let k in this.steps) {
        if (k <= step) {
          this.steps[k] = true;
        } else {
          this.steps[k] = false;
        }
      }
    },
    buildQuerydata: function () {
      console.log(this.printOnlyDigits)
      const raw = this.$data;
      const allowed = [
        "payload",
        "shapeSelected",
        "shapeSelectedId",
        "pattern",
        "paletteSelected",
        "firstMethodSelected",
        "additionalMethodSelected",
        "patternLenSelected",
        "printDefaultMandala",
        "printOnlyDigits",
        "printDotsByNumbers",
        "printOnlyDots",
        "sectorLength",
      ];
      const filtered = Object.keys(raw)
        .filter((key) => allowed.includes(key))
        .reduce((obj, key) => {
          obj[key] = raw[key];
          return obj;
        }, {});
      return filtered;
    },
    gotoPrint() {
      let route = this.$router.resolve({
        path: "/print",
        query: this.buildQuerydata(),
      });
      window.open(route.href);
      this.print_open = false;
    },
    selectShapeId(e) {
      this.shapeSelectedId = e.id;
    },
    figureSelected() {
      console.log("figureSelected called with shape:", this.shapeSelected);
      this.gotoStep(2);
      this.draw_url = `${API_URL}/draw/api/v1/premium/shape/${this.shapeSelected}?`;

      if (typeof this.shapeSelected !== 'undefined' && this.shapeSelected !== "") {
        console.log("Making API request for shape data");
        axiosInstance
          .get(`${API_URL}/draw/api/v1/premium/shapesdata/`, {
            params: {
              shape: this.shapeSelected,
              q: this.payload,
            },
          })
          .then((response) => {
            console.log("API response received:", response.status);
            return response.data;
          })
          .then((data) => {
            console.log("Shape data:", data);
            // Make sure we're not overriding the entire commondata object
            if (data) {
              // Only update specific properties
              Object.keys(data).forEach(key => {
                this.commondata[key] = data[key];
              });

              if (data.default_method) {
                this.firstMethodSelected = data.default_method;
              }
              if (data.default_additional_method) {
                this.additionalMethodSelected = data.default_additional_method;
              }
              if (data.main_matrix) {
                this.main_matrix = data.main_matrix;
              }

              var min_val = Math.min(
                this.payload.length,
                this.commondata["pattern_max_len"] || 10,
                this.payloadFactLength || 10
              ) || 1;

              this.patternsList = Array.from(Array(min_val).keys());
              this.patternClick();
            } else {
              console.error("Empty data received from API");
            }
          })
          .catch(error => {
            console.error("Error fetching shape data:", error);
          });
      } else {
        console.warn("Shape not selected or undefined");
      }
    },
    patternClick() {
      this.patternSymbols =
        this.commondata["main_matrix"][this.paletteSelected][
        this.commondata["main_matrix"][this.paletteSelected].length -
        this.patternLenSelected
        ];
      this.gotoStep(2);
    },
    sendDrawRequest(data) {
      let url = this.draw_url + encodeQueryData(data);
      this.isLoading = true;
      return axiosInstance.get(url, this.sec_headers)
        .then((response) => {
          this.isLoading = false;
          this.b64image = response.data.pic || "";
          this.pattern = response.data.pattern;

          // Check if we have remaining drawings info in response
          if (response.data.remaining_drawings !== undefined) {
            if (!this.userInfo) this.userInfo = {};
            if (!this.userInfo.mandala_drawings) this.userInfo.mandala_drawings = {};
            this.userInfo.mandala_drawings.remaining = response.data.remaining_drawings;
          }

          // Call userDataRefresh if it exists
          if (this.userDataRefresh) {
            this.userDataRefresh();
          }
        })
        .catch((error) => {
          this.isLoading = false;
          console.error("API Error:", error);

          // Set error message
          let errorMessage = this.$t('message.errorTitle')+': ';

          // Get specific error message if available
          if (error.response && error.response.data && error.response.data.error) {
            errorMessage += error.response.data.error;
          } else if (error.message) {
            errorMessage += error.message;
          } else {
            errorMessage += this.$t('message.networkError');
          }

          this.errorMessage = errorMessage;
          this.showErrorModal = true;
        });
    },
    processrequest() {
      console.log("processing request");
      switch (this.mandalaForm) {
        case "colorized":
          console.log("colorizing mandala");
          this.colorizeShape();
          break;
        case "digits":
          this.unColorizeShape();
          break;
        case "dots":
          this.filterShape();
          break;
      }
      this.gotoStep(3);
    },
    colorizeShape() {
      const data = {
        q: this.payload,
        patternLength: this.patternLenSelected,
        palette_id: this.paletteSelected,
        first_method: this.firstMethodSelected,
        additional_method: this.additionalMethodSelected,
        sector_length: this.sectorLength,
        colorize: 1,
      };
      this.sendDrawRequest(data);
    },
    unColorizeShape() {
      const data = {
        q: this.payload,
        patternLength: this.patternLenSelected,
        palette_id: this.paletteSelected,
        first_method: this.firstMethodSelected,
        additional_method: this.additionalMethodSelected,
        sector_length: this.sectorLength,
        colorize: 0,
        filter: "*",
      };
      this.sendDrawRequest(data);
    },
    filterShape() {
      const data = {
        q: this.payload,
        patternLength: this.patternLenSelected,
        palette_id: this.paletteSelected,
        first_method: this.firstMethodSelected,
        additional_method: this.additionalMethodSelected,
        sector_length: this.sectorLength,
        colorize: 0,
        num_to_dot: 1,
        filter: this.pattern,
      };
      this.sendDrawRequest(data);
    },
    phraseChange() {
      this.b64image = "";
      this.gotoStep(1);
      this.shapeSelected = "";
      this.pattern = "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20";
      this.firstMethodSelected = "";
      this.additionalMethodSelected = "";
      delete this.commondata["methods"];
    },
    figureChange() {
      this.b64image = "";
      this.gotoStep(1);
      this.pattern = "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20";
      this.firstMethodSelected = "";
      this.additionalMethodSelected = "";
      delete this.commondata["methods"];
      this.patternLenSelected = 1;
      this.paletteSelected = 9;
      this.figureSelected();
    },
    firstMethodSelect() {
      this.additionalMethodSelected = "";
    },
    openHelpModal() {
      this.showHelpModal = true;
    }
  }
}
</script>
<style scoped>
label.btn.btn-outline-primary {
  min-width: 45px;
}

.matrixCell {
  max-width: 30px;
}

.step-indicator {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}

.step-bubble {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 8px;
  font-weight: bold;
  transition: all 0.3s ease;
}

.step-line {
  height: 2px;
  flex-grow: 1;
  margin: auto 4px;
  max-width: 60px;
}

.step-active {
  background-color: #3b82f6;
  color: white;
  transform: scale(1.1);
}

.step-completed {
  background-color: #10b981;
  color: white;
}

.step-inactive {
  background-color: #e5e7eb;
  color: #6b7280;
}

.line-active {
  background-color: #3b82f6;
}

.line-inactive {
  background-color: #e5e7eb;
}

.mandala-placeholder {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 2px dashed #d1d5db;
  border-radius: 0.5rem;
  padding: 2rem;
  text-align: center;
  height: 100%;
  min-height: 400px;
}

.mandala-placeholder-icon {
  font-size: 3rem;
  margin-bottom: 1rem;
  color: #9ca3af;
}

.remaining-counter {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: rgba(59, 130, 246, 0.9);
  color: white;
  padding: 0.375rem 0.75rem;
  border-radius: 9999px;
  font-size: 0.875rem;
  font-weight: 600;
  z-index: 10;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.section-heading {
  font-weight: 600;
  color: #4b5563;
  margin-bottom: 0.75rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 0px;
}

.section-heading-number {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #3b82f6;
  color: white;
  font-size: 0.875rem;
  margin-right: 0.5rem;
}

.help-button {
  margin-left: auto;
  cursor: pointer;
  color: #3b82f6;
  transition: color 0.2s ease;
}

.help-button:hover {
  color: #2563eb;
}

.help-text {
  color: #6b7280;
  font-size: 0.875rem;
  margin-bottom: 0.75rem;
}

.instruction-step {
  margin-bottom: 1rem;
  padding: 1rem;
  border-radius: 0.5rem;
  background-color: #f3f4f6;
}

.instruction-step-active {
  background-color: #eff6ff;
  border-left: 4px solid #3b82f6;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  transform: scale(0.95);
}
</style>
<template>
  <div class="container mx-auto px-4 relative">
    <!-- Remaining counter badge -->
    <div v-if="remainingMandalaDrawings !== null" class="remaining-counter">
      <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
        <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm0-2a6 6 0 100-12 6 6 0 000 12z"
          clip-rule="evenodd" />
        <path d="M10 8a1 1 0 011 1v2a1 1 0 11-2 0V9a1 1 0 011-1z" />
        <path d="M10 6a1 1 0 100-2 1 1 0 000 2z" />
      </svg>
      {{ $t('message.remainingMandala') }}: {{ remainingMandalaDrawings }} / {{ totalMandalaDrawings }}
    </div>

    <!-- Step Indicator -->
    <div class="step-indicator mb-2">
      <div
        :class="['step-bubble', currentStep >= 1 ? (currentStep > 1 ? 'step-completed' : 'step-active') : 'step-inactive']">
        1</div>
      <div :class="['step-line', currentStep > 1 ? 'line-active' : 'line-inactive']"></div>
      <div
        :class="['step-bubble', currentStep >= 2 ? (currentStep > 2 ? 'step-completed' : 'step-active') : 'step-inactive']">
        2</div>
      <div :class="['step-line', currentStep > 2 ? 'line-active' : 'line-inactive']"></div>
      <div :class="['step-bubble', currentStep >= 3 ? 'step-active' : 'step-inactive']">3</div>
    </div>

    <div class="grid grid-cols-1 md:grid-cols-8 gap-6">
      <!-- Left Column - Image Display or Instructions -->
      <div class="md:col-span-5 relative" style="min-height: 400px">
        <img
          v-if="b64image !== ''"
          v-bind:src="'data:image/jpeg;base64,' + b64image"
          class="img-fluid rounded-lg shadow-md mx-auto"
          :style="{
            maxHeight: 'calc(100vh - 250px)', // 200px content + 50px padding/margin
            maxWidth: '100%',
            objectFit: 'contain',
            display: 'block'
          }"
          alt="Ваша мандала"
        />
        <div v-else class="mandala-placeholder">
          <div class="mandala-placeholder-icon">🔮</div>
          <h3 class="text-xl font-medium text-gray-700 mb-4">{{ $t('message.createYourMandala') }}</h3>

          <div v-if="currentStep === 1" class="max-w-md">
            <div class="instruction-step instruction-step-active">
              <h4 class="font-medium mb-2">{{ $t('message.step1Title') }}</h4>
              <p class="help-text">{{ $t('message.step1Description') }}</p>
            </div>
            <div class="instruction-step">
              <h4 class="font-medium mb-2">{{ $t('message.step2Title') }}</h4>
              <p class="help-text">{{ $t('message.step2Description') }}</p>
            </div>
            <div class="instruction-step">
              <h4 class="font-medium mb-2">{{ $t('message.step3Title') }}</h4>
              <p class="help-text">{{ $t('message.step3Description') }}</p>
            </div>
          </div>

          <div v-else-if="currentStep === 2" class="max-w-md">
            <div class="instruction-step">
              <h4 class="font-medium mb-2">{{ $t('message.step1Title') }}</h4>
              <p class="help-text">{{ $t('message.step1Complete') }}</p>
            </div>
            <div class="instruction-step instruction-step-active">
              <h4 class="font-medium mb-2">{{ $t('message.step2Title') }}</h4>
              <p class="help-text">{{ $t('message.step2Active') }}</p>
            </div>
            <div class="instruction-step">
              <h4 class="font-medium mb-2">{{ $t('message.step3Title') }}</h4>
              <p class="help-text">{{ $t('message.step3Description') }}</p>
            </div>
          </div>

          <!-- Help button for all instruction views -->
          <button @click="openHelpModal"
            class="mt-6 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700">
            <QuestionMarkCircleIcon class="h-5 w-5 mr-2" />
            {{ $t('message.needHelp') }}
          </button>
        </div>
      </div>

      <!-- Right Column - Controls -->
      <div class="md:col-span-3">
          <!-- Step 1: Enter Intention -->
          <div class="rounded-lg border border-gray-200 p-4 mb-1 pb-1 shadow-sm">
            <div class="section-heading">
              <span class="section-heading-number">1</span>
              {{ $t('message.intentionHeading') }}
              <span class="help-button" @click="openHelpModal">
                <QuestionMarkCircleIcon class="h-5 w-5" />
              </span>
            </div>
            <p class="help-text">{{ $t('message.intentionHelp') }}</p>

            <div class="mb-1">
              <textarea id="wishtext" v-model="payload" rows="4"
                class="border mt-1 p-2 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                @change="phraseChange" aria-describedby="wishhelp"
                :placeholder="$t('message.intentionPlaceholder')"></textarea>
            </div>
          </div>

          <!-- Step 2: Shape Selection -->
          <div class="rounded-lg border border-gray-200 p-4 mb-6 shadow-sm"
            v-if="payload.length > 5"
            :class="{ 'bg-gray-50': !steps[1], 'bg-white': steps[1] }">
            <div class="section-heading mb-0">
              <span class="section-heading-number">2</span>
              {{ $t('message.shapeSelectionHeading') }}
            </div>
            <p class="help-text">{{ $t('message.shapeSelectionHelp') }}</p>

            <div v-if="steps[1]">
              <select id="chooseShape" v-model="shapeSelected"
                class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                aria-label="{{ $t('message.shapes') }}" :disabled="payload.length < 5" @change="figureChange">
                <option value="" disabled selected>{{ $t('message.selectShape') }}</option>
                <option v-for="(item, index) in commondata.menu.shapes[computedLocale]" :key="index" :value="item.name">
                  {{ item.displayLabel }}
                </option>
              </select>

              <div v-if="firstMethodLength > 0" class="mt-4">
                <label for="methodOne" class="block text-sm font-medium text-gray-700">{{ $t('message.methodOne')
                }}</label>
                <select id="methodOne" v-model="firstMethodSelected"
                  class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  @change="firstMethodSelect">
                  <option value="" disabled selected>{{ $t('message.selectMethod') }}</option>
                  <option v-for="(item, index) in firstMethod" :key="index" :value="index">
                    {{ item.method_label }}
                  </option>
                </select>
              </div>
            </div>

            <div v-if="additionalMethodLength > 0" class="mt-4">
              <label for="methodTwo" class="block text-sm font-medium text-gray-700">{{ $t("message.methodTwo") }}</label>
              <select id="methodTwo" v-model="additionalMethodSelected"
                class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                <option value="" disabled selected>{{ $t('message.selectAdditionalMethod') }}</option>
                <option v-for="(item, index) in additionalMethod" :key="index" :value="item.method_additional_name">
                  {{ item.method_additional_label }}
                </option>
              </select>
            </div>

            <!-- Pattern length selection -->
            <div class="step-2 mt-6" v-if="steps[2]">
              <div class="mb-1">
                <label class="block text-sm font-medium text-gray-700 mb-2">{{ $t("message.patternLength") }}</label>
                <div class="grid grid-cols-7 rounded-xl gap-2">
                  <template v-for="patternValue in patternsList" v-bind:key="patternValue">
                    <template v-if="patternValue * 1 <= 11">
                      <div>
                        <input type="radio" :id="'patternlen-' + (patternValue + 1)" name="brt-patternlen"
                          :value="patternValue + 1" class="hidden peer" v-model="patternLenSelected"
                          @change="patternClick($event)">
                        <label :for="'patternlen-' + (patternValue + 1)" :class="[
                          'inline-flex justify-center items-center py-2 min-w-10 text-sm text-white font-medium border border-gray-300 rounded-lg focus:ring-4 focus:ring-blue-300',
                          patternLenSelected === patternValue + 1
                            ? 'bg-blue-600 text-white'
                            : 'bg-blue-800 hover:bg-gray-100 dark:bg-white dark:text-black dark:hover:bg-gray-600 dark:hover:border-gray-600'
                        ]">
                          {{ patternValue + 1 }}
                        </label>
                      </div>
                    </template>
                  </template>
                </div>
              </div>

              <!-- Pattern information -->
              <div v-if="patternSymbols != '' && this.steps[3]" class="mb-1">
                <div class="p-3 bg-gray-50 rounded-md font-mono">
                  <span class="text-sm font-medium text-gray-700 mb-2">{{ $t("message.pattern") }}:</span>
                  {{ patternSymbols }}
                  <button @click="open = true" class="text-blue-600 hover:text-blue-800 text-sm ml-2">
                    {{ $t("message.seeAll") }}
                  </button>
                </div>
              </div>

              <!-- Sector Length (conditional) -->
              <div class="mb-4" v-if="showSectorLength">
                <label class="block text-sm font-medium text-gray-700 mb-2">{{ $t("message.sectorLength") }}</label>
                <input type="number"
                  class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  id="sectorLength" aria-describedby="sectorLengthHelp" v-model="sectorLength" min="4" max="16" />
                <p id="sectorLengthHelp" class="mt-1 text-sm text-gray-500">{{ $t("message.sectorLengthHelp") }}</p>
              </div>

              <!-- Palette Selection -->
              <div class="mb-4">
                <label class="block text-sm font-medium text-gray-700 mb-2">{{ $t("message.paletteSelect") }}</label>
                <div class="grid grid-cols-3 gap-2 rounded-xl">
                  <div v-for="value in [9, 12, 13]" :key="value">
                    <input type="radio" :id="'btr-pal' + value" name="btr-palette" :value="value" class="hidden peer"
                      v-model="paletteSelected" @change="patternClick($event)" autocomplete="off" />
                    <label :for="'btr-pal' + value" :class="[
                      'inline-flex justify-center items-center py-2 min-w-10 text-sm font-medium border border-gray-300 rounded-lg focus:ring-4 focus:ring-blue-300',
                      paletteSelected === value
                        ? 'bg-blue-600 text-white'
                        : 'bg-gray-200 hover:bg-gray-300 text-gray-800'
                    ]">{{ value }}</label>
                  </div>
                </div>

                <!-- Palette Legend -->
                <div class="mt-3">
                  <label class="block text-sm font-medium text-gray-700 mb-2">{{ $t("message.paletteLegend") }}</label>
                  <div class="overflow-hidden rounded-lg border border-gray-200">
                    <table class="w-full" style="table-layout: fixed; text-align: center">
                      <tbody>
                        <tr>
                          <td v-for="color in paletteRendered[1]" v-bind:key="color.id" v-bind:style="{
                            backgroundColor: color.hex,
                            width: '35px',
                            height: '30px'
                          }">
                            <span class="text-xs font-semibold"
                              :style="{ color: isColorDark(color.hex) ? 'white' : 'black' }">{{
                              color.id }}</span>
                          </td>
                        </tr>
                        <tr>
                          <td v-for="color in paletteRendered[2]" v-bind:key="color.id" v-bind:style="{
                            backgroundColor: color.hex,
                            width: '35px',
                            height: '30px'
                          }">
                            <span class="text-xs font-semibold"
                              :style="{ color: isColorDark(color.hex) ? 'white' : 'black' }">{{
                              color.id }}</span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <!-- View Options -->
            </div>

            <!-- Pattern matrix editor (for dots view) -->
            <div
              class="mb-3">
              <div class="inline-flex rounded-md shadow-sm" role="group" aria-label="Basic radio toggle button group">
                  <div class="relative">
                    <input
                      type="radio"
                      class="absolute w-full h-full opacity-0 z-10 cursor-pointer peer"
                      id="btnradio1"
                      value="colorized"
                      :checked="mandalaForm === 'colorized'"
                      @change="mandalaForm = 'colorized'"
                      name="btnradio"
                      autocomplete="off"
                    />
                    <label
                      for="btnradio1"
                      class="inline-flex items-center justify-center px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-00 rounded-l-lg hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-white dark:border-gray-300  dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white peer-checked:bg-blue-600 peer-checked:text-white"
                    >
                      {{ $t("message.colorizedCaption") }}
                    </label>
                  </div>

                  <div class="relative">
                    <input
                      type="radio"
                      class="absolute w-full h-full opacity-0 z-10 cursor-pointer peer"
                      id="btnradio2"
                      value="digits"
                      :checked="mandalaForm === 'digits'"
                      @change="mandalaForm = 'digits'"
                      name="btnradio"
                      autocomplete="off"
                    />
                    <label
                      for="btnradio2"
                      class="inline-flex items-center justify-center px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-00  hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-white dark:border-gray-300  dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white peer-checked:bg-blue-600 peer-checked:text-white"
                    >
                      {{ $t("message.digitsCaption") }}
                    </label>
                  </div>

                  <div class="relative" v-if="steps[3]">
                    <input
                      type="radio"
                      class="absolute w-full h-full opacity-0 z-10 cursor-pointer peer"
                      id="btnradio3"
                      value="dots"
                      :checked="mandalaForm === 'dots'"
                      @change="mandalaForm = 'dots'"
                      name="btnradio"
                      autocomplete="off"
                    />
                    <label
                      for="btnradio3"
                      class="inline-flex items-center justify-center px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-00 rounded-r-lg hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-white dark:border-gray-300  dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white peer-checked:bg-blue-600 peer-checked:text-white"
                    >
                      {{ $t("message.dotsCaption") }}
                    </label>
                  </div>
              </div>
            </div>

            <!-- Action buttons -->
            <div
              class="mb-3"
              v-if="b64image != '' && steps[3] && mandalaForm == 'dots'"
            >
              <div class="mb-4">
                <label for="mainMatrix" class="block mb-2 text-sm font-medium text-gray-700">
                  {{ $t("message.yourMatrix") }}
                </label>
                <input
                  type="text"
                  id="mainMatrix"
                  v-model="pattern"
                  aria-describedby="wishhelp"
                  class="w-full px-3 py-2 text-sm text-gray-700 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                />
              </div>
            </div>
            <div class="inline-flex rounded-md shadow-sm" role="group" aria-label="Basic outlined example">
              <button
                v-if="steps[2]"
                type="button"
                class="px-4 py-2 text-sm font-medium text-blue-600 bg-white border border-blue-200 rounded-l-md hover:bg-blue-50 focus:z-10 focus:ring-2 focus:ring-blue-500 focus:text-blue-600 transition-colors duration-200"
                @click="processrequest"
                id="btnGroupAddon1"
              >
                {{ $t("message.go") }}
              </button>
              <button
                v-if="b64image && steps[3]"
                type="button"
                class="px-4 py-2 text-sm font-medium text-blue-600 bg-white border border-blue-200 rounded-r-md hover:bg-blue-50 focus:z-10 focus:ring-2 focus:ring-blue-500 focus:text-blue-600 transition-colors duration-200"
                role="link"
                @click="print_open = true"
              >
                {{ $t("message.print") }}
              </button>
            </div>
          </div>
      </div>
    </div>
    <!-- Matrix View Modal -->
    <Transition name="fade">
      <div v-if="open" class="fixed inset-0 z-50 overflow-y-auto">
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75" @click="open = false"></div>
        <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <div
            class="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
            <div class="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
              <div class="sm:flex sm:items-start">
                <div
                  class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                  <ExclamationTriangleIcon class="h-6 w-6 text-red-600" aria-hidden="true" />
                </div>
                <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                  <h3 class="text-base font-semibold leading-6 text-gray-900">{{ $t("message.matrixOfPatterns") }}</h3>
                  <div class="mt-2">
                    <div class="overflow-x-auto">
                      <table v-if="1 != 0" class="table-fixed w-full text-center">
                        <tbody>
                          <tr>
                            <template v-for="(symbol, idx) in fakePayload" :key="idx">
                              <td class="matrixCell px-4 py-2" v-show="payloadRegex.test(symbol)">
                                {{ symbol }}
                              </td>
                            </template>
                          </tr>
                          <tr v-for="(item, index) in mainMatrixRendered" :key="index">
                            <td class="matrixCell px-4 py-2" v-for="(elem, indexj) in item" :key="indexj">
                              {{ elem }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
              <button type="button"
                class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                @click="open = false">OK</button>
            </div>
          </div>
        </div>
      </div>
    </Transition>

    <!-- Print Options Modal -->
    <Transition name="fade">
      <div v-if="print_open" class="fixed inset-0 z-50 overflow-y-auto">
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75" @click="print_open = false"></div>
        <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <div
            class="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl">
            <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
              <div class="sm:flex sm:items-start">
                <div
                  class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                  <PrinterIcon class="h-6 w-6 text-blue-600" aria-hidden="true" />
                </div>
                <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
                  <h3 class="text-lg font-medium leading-6 text-gray-900 mb-4">
                    {{ $t("message.printsCaption") }}
                  </h3>
                  <div class="mt-2 space-y-4">
                    <div class="flex items-center">
                      <input id="printDefaultMandala" type="checkbox" v-model="printDefaultMandala"
                        :checked="printDefaultMandala"
                        class="w-5 h-5 text-blue-600 border-gray-300 rounded focus:ring-blue-500" />
                      <label for="printDefaultMandala" class="ml-3 text-sm font-medium text-gray-700">
                        {{ $t("message.colorizedCaption") }}
                      </label>
                    </div>
                    <div class="flex items-center">
                      <input id="printOnlyDigits" v-model="printOnlyDigits" type="checkbox" :checked="printOnlyDigits"
                        class="w-5 h-5 text-blue-600 border-gray-300 rounded focus:ring-blue-500" />
                      <label for="printOnlyDigits" class="ml-3 text-sm font-medium text-gray-700">
                        {{ $t("message.digitsCaption") }}
                      </label>
                    </div>
                    <div class="flex items-center">
                      <input id="printOnlyDots" type="checkbox" v-model="printOnlyDots" :checked="printOnlyDots"
                        class="w-5 h-5 text-blue-600 border-gray-300 rounded focus:ring-blue-500" />
                      <label for="printOnlyDots" class="ml-3 text-sm font-medium text-gray-700">
                        {{ $t("message.dotsCaption") }}
                      </label>
                    </div>
                    <div class="flex items-center">
                      <input id="printDotsByNumbers" type="checkbox" v-model="printDotsByNumbers"
                        :checked="printDotsByNumbers"
                        class="w-5 h-5 text-blue-600 border-gray-300 rounded focus:ring-blue-500" />
                      <label for="printDotsByNumbers" class="ml-3 text-sm font-medium text-gray-700">
                        {{ $t("message.dotsByNumberCaption") }}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
              <button @click="gotoPrint" type="button"
                class="inline-flex justify-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:ml-3 sm:w-auto">
                {{ $t("message.print") }}
              </button>
              <button @click="print_open = false" type="button"
                class="mt-3 inline-flex justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:mt-0 sm:w-auto">
                {{ $t("message.close") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </Transition>

    <!-- Help Modal -->
    <Transition name="fade">
      <div v-if="showHelpModal" class="fixed inset-0 z-50 overflow-y-auto">
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75" @click="showHelpModal = false"></div>
        <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <div
            class="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl">
            <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
              <div class="sm:flex sm:items-start mb-5">
                <div
                  class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                  <QuestionMarkCircleIcon class="h-6 w-6 text-blue-600" aria-hidden="true" />
                </div>
                <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <h3 class="text-lg font-semibold leading-6 text-gray-900">
                    {{ $t("message.helpModalTitle") }}
                  </h3>
                </div>
              </div>

              <div class="space-y-6">
                <!-- Step 1 -->
                <div class="bg-blue-50 p-4 rounded-lg border-l-4 border-blue-500">
                  <h4 class="text-md font-medium text-blue-800 flex items-center">
                    <span
                      class="flex items-center justify-center w-6 h-6 bg-blue-600 text-white rounded-full text-xs mr-2">1</span>
                    {{ $t("message.step1Title") }}
                  </h4>
                  <p class="mt-2 text-sm text-blue-700">{{ $t("message.step1HelpDetail") }}</p>
                  <div class="mt-3 bg-white p-3 rounded border border-blue-200">
                    <p class="text-sm text-gray-600 italic">{{ $t("message.step1Example") }}</p>
                  </div>
                </div>

                <!-- Step 2 -->
                <div class="bg-indigo-50 p-4 rounded-lg border-l-4 border-indigo-500">
                  <h4 class="text-md font-medium text-indigo-800 flex items-center">
                    <span
                      class="flex items-center justify-center w-6 h-6 bg-indigo-600 text-white rounded-full text-xs mr-2">2</span>
                    {{ $t("message.step2Title") }}
                  </h4>
                  <p class="mt-2 text-sm text-indigo-700">{{ $t("message.step2HelpDetail") }}</p>
                  <ul class="mt-2 text-sm text-indigo-700 list-disc pl-5 space-y-1">
                    <li>{{ $t("message.helpSelectShape") }}</li>
                    <li>{{ $t("message.helpSelectMethod") }}</li>
                    <li>{{ $t("message.helpSelectPatternLength") }}</li>
                    <li>{{ $t("message.helpSelectPalette") }}</li>
                  </ul>
                </div>

                <!-- Step 3 -->
                <div class="bg-purple-50 p-4 rounded-lg border-l-4 border-purple-500">
                  <h4 class="text-md font-medium text-purple-800 flex items-center">
                    <span
                      class="flex items-center justify-center w-6 h-6 bg-purple-600 text-white rounded-full text-xs mr-2">3</span>
                    {{ $t("message.step3Title") }}
                  </h4>
                  <p class="mt-2 text-sm text-purple-700">{{ $t("message.step3HelpDetail") }}</p>
                  <p class="mt-2 text-sm text-purple-700">{{ $t("message.helpViewOptions") }}</p>
                </div>

                <!-- Tips -->
                <div class="bg-gray-50 p-4 rounded-lg">
                  <h4 class="text-md font-medium text-gray-800">{{ $t("message.helpTipsTitle") }}</h4>
                  <ul class="mt-2 text-sm text-gray-600 list-disc pl-5 space-y-1">
                    <li>{{ $t("message.helpTip1") }}</li>
                    <li>{{ $t("message.helpTip2") }}</li>
                    <li>{{ $t("message.helpTip3") }}</li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="bg-gray-50 px-4 py-3 flex justify-end">
              <button type="button"
                class="inline-flex justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                @click="showHelpModal = false">
                {{ $t("message.gotIt") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </Transition>
    <!-- Error Modal -->
    <Transition name="fade">
      <div v-if="showErrorModal" class="fixed inset-0 z-50 overflow-y-auto">
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75" @click="showErrorModal = false"></div>
        <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <div
            class="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
            <div class="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
              <div class="sm:flex sm:items-start">
                <div
                  class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                  <ExclamationTriangleIcon class="h-6 w-6 text-red-600" aria-hidden="true" />
                </div>
                <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                  <h3 class="text-base font-semibold leading-6 text-gray-900">{{ $t("message.errorTitle")}}</h3>
                  <div class="mt-2">
                    <p class="text-sm text-gray-500">{{ errorMessage }}</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
              <button @click="showErrorModal = false" type="button"
                class="inline-flex justify-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:ml-3 sm:w-auto">
                {{ $t("message.gotIt") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </Transition>
  </div>
</template>
