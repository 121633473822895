<template>
  <div class="min-h-screen bg-gradient-to-br from-indigo-50 via-purple-50 to-pink-50 py-12">
    <div class="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
      <!-- Subscription Alert Banner (only shown when needed) -->
      <div
        v-if="!userProfile.subscription_status?.is_active"
        class="mb-8 transform transition-all duration-300 hover:scale-105"
      >
        <div class="relative overflow-hidden rounded-xl bg-gradient-to-r from-violet-600 via-purple-600 to-indigo-600 p-[2px]">
          <div class="relative rounded-xl bg-gradient-to-r from-violet-600 via-purple-600 to-indigo-600">
            <div class="px-6 py-8">
              <div class="flex flex-col md:flex-row items-center justify-between gap-6">
                <div class="flex items-center space-x-4">
                  <div class="flex h-12 w-12 items-center justify-center rounded-full bg-white/10">
                    <span class="text-2xl">⚡️</span>
                  </div>
                  <div>
                    <h3 class="text-xl font-bold text-white">
                      {{ $t('message.unlockAllFeatures') }}
                    </h3>
                    <p class="text-indigo-200">
                      {{ $t('message.limitedFeatures') }} ✨
                    </p>
                  </div>
                </div>
                <router-link
                  to="/payment"
                  class="group relative overflow-hidden rounded-lg bg-white px-6 py-3 text-indigo-600 shadow-md transition-all duration-300 hover:shadow-lg hover:scale-105"
                >
                  <div class="absolute inset-0 bg-gradient-to-r from-violet-400 to-indigo-400 opacity-0 transition-opacity group-hover:opacity-10" />
                  <span class="flex items-center font-semibold">
                    {{ $t('message.upgradeNow') }} 🚀
                  </span>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Profile Card with Loading and Error States -->
      <div v-if="isLoading" class="bg-white rounded-2xl shadow-xl p-8 flex justify-center items-center min-h-[200px]">
        <div class="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-indigo-600"></div>
      </div>

      <div v-else-if="errors.length > 0" class="bg-white rounded-2xl shadow-xl p-8">
        <div class="p-4 bg-red-100 border border-red-400 text-red-700 rounded-lg" role="alert">
          <strong class="font-bold">{{ $t('message.errorTitle') }}</strong>
          <ul class="mt-1">
            <li v-for="(error, index) in errors" :key="index">{{ error }}</li>
          </ul>
        </div>
      </div>

      <!-- Main Profile Content -->
      <div v-else class="space-y-6">
        <!-- User Header Card -->
        <div class="bg-white rounded-2xl shadow-md overflow-hidden">
          <div class="p-6 bg-gradient-to-r from-indigo-500/5 via-purple-500/5 to-pink-500/5">
            <div class="flex flex-col sm:flex-row items-center gap-6">
              <div class="relative group">
                <div class="absolute -inset-0.5 bg-gradient-to-r from-indigo-500 to-purple-500 rounded-full opacity-50 group-hover:opacity-100 blur transition duration-300"></div>
                <img
                  :src="userProfile.avatar || '/src/assets/images/person-3.png'"
                  :alt="userProfile.username"
                  class="relative h-24 w-24 rounded-full object-cover border-4 border-white shadow-lg"
                />
              </div>
              <div>
                <h1 class="text-2xl font-bold text-gray-900">
                  {{ userProfile.first_name || userProfile.username }} ✌️
                </h1>
                <p class="text-sm text-gray-500">
                  {{ $t('message.joinedCaption') }} {{ formatDate(userProfile.joined_date) }} 🎉
                </p>
              </div>
            </div>
          </div>
        </div>

        <!-- Subscription Status Card -->
        <div class="bg-white rounded-2xl shadow-md overflow-hidden">
          <div class="p-6">
            <h2 class="text-lg font-semibold text-gray-900 mb-4 flex items-center gap-2">
              {{ $t('message.subscriptionStatus') }}
              <span v-if="userProfile.subscription_status?.is_active">💫</span>
              <span v-else>🔒</span>
            </h2>

            <div v-if="userProfile.subscription_status?.is_active && userProfile.subscription_details"
                 class="bg-gradient-to-r from-green-50 to-emerald-50 rounded-xl p-6 border border-green-100">
              <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div>
                  <p class="text-sm text-gray-600">{{ $t('message.subscriptionPlan') }}</p>
                  <p class="font-semibold text-gray-900">{{ userProfile.subscription_details.plan_name }}</p>
                </div>
                <div>
                  <p class="text-sm text-gray-600">{{ $t('message.status') }}</p>
                  <span class="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium"
                        :class="getStatusClass">
                    {{ $t(`message.${userProfile.subscription_details.status}Status`) }} ✨
                  </span>
                </div>
                <div>
                  <p class="text-sm text-gray-600">{{ $t('message.validUntil') }}</p>
                  <p class="font-semibold text-gray-900">
                    {{ formatDate(userProfile.subscription_details.end_date) }}
                  </p>
                </div>
                <div>
                  <p class="text-sm text-gray-600">{{ $t('message.daysRemaining') }}</p>
                  <p class="font-semibold text-gray-900">
                    {{ userProfile.subscription_details.days_remaining }} {{ $t('message.days') }} ⏳
                  </p>
                </div>
              </div>

              <!-- Mandala Drawings Tracker -->
              <div class="mt-6 pt-6 border-t border-green-100">
                <h3 class="text-sm font-medium text-gray-700 mb-4">{{ $t('message.mandalaDrawings') }}</h3>

                <div class="space-y-4">
                  <!-- Progress bar -->
                  <div>
                    <div class="flex justify-between items-center mb-1">
                      <span class="text-xs font-medium text-indigo-600">
                        {{ userProfile.mandala_drawings?.used || 0 }} / {{ userProfile.mandala_drawings?.allowed || 0 }}
                      </span>
                      <span class="text-xs font-medium text-indigo-600">
                        {{ userProfile.mandala_drawings?.remaining || 0 }} {{ $t('message.drawingsRemaining') }}
                      </span>
                    </div>
                    <div class="w-full bg-gray-200 rounded-full h-2.5">
                      <div class="h-2.5 rounded-full"
                           :style="`width: ${calculateDrawingsProgress()}%`"
                           :class="[
                             isDrawingLimitReached ? 'bg-red-600' :
                             isDrawingLimitLow ? 'bg-orange-500' : 'bg-green-600'
                           ]">
                      </div>
                    </div>
                  </div>

                  <!-- Drawing status message -->
                  <div class="text-sm">
                    <div v-if="isDrawingLimitReached" class="text-red-600 flex items-center">
                      <span class="mr-2">⚠️</span>
                      {{ $t('message.noDrawingsLeft') }}
                    </div>
                    <div v-else-if="isDrawingLimitLow" class="text-orange-600 flex items-center">
                      <span class="mr-2">⚠️</span>
                      {{ $t('message.lowDrawingsLeft') }}
                    </div>
                    <div v-else class="text-green-600 flex items-center">
                      <span class="mr-2">✅</span>
                      {{ $t('message.drawingsAvailable') }}
                    </div>
                  </div>

                  <div class="text-xs text-gray-500">
                    {{ $t('message.resetsOn') }}: {{ formatDate(userProfile.mandala_drawings?.reset_date) }}
                  </div>
                </div>
              </div>
            </div>

            <div v-else
                 class="bg-gradient-to-r from-gray-50 to-indigo-50 rounded-xl p-8 border border-indigo-100 shadow-sm text-center">
              <div class="mb-6">
                <span class="inline-block text-4xl mb-2">🎯</span>
                <h3 class="text-lg font-semibold text-gray-900">
                  {{ $t('message.noActiveSubscription') }}
                </h3>
                <p class="mt-2 text-gray-600">
                  {{ $t('message.limitedFeatures') }}
                </p>
              </div>
              <router-link
                to="/payment"
                class="inline-flex items-center px-6 py-3 text-base font-medium rounded-xl text-white bg-gradient-to-r from-indigo-600 to-purple-600 hover:from-indigo-500 hover:to-purple-500 transform transition-all duration-300 hover:scale-105"
              >
                {{ $t('message.upgradeNow') }} ⭐️
              </router-link>
            </div>
          </div>
        </div>

        <!-- NEWLY ADDED: Purchased Learning Sets Card -->
        <div v-if="hasPurchasedLearningSets" class="bg-white rounded-2xl shadow-md overflow-hidden">
          <div class="p-6">
            <h2 class="text-lg font-semibold text-gray-900 mb-4 flex items-center gap-2">
              {{ $t('message.purchasedLearningSets') }} 📚
            </h2>

            <div class="space-y-4">
              <div v-for="learningSet in userProfile.purchased_learning_sets" :key="learningSet.id"
                   class="bg-gradient-to-r from-blue-50 to-indigo-50 rounded-xl p-4 border border-blue-100 transition-all duration-300 hover:shadow-md">
                <div class="flex items-start gap-4">
                  <div class="flex-shrink-0">
                    <div class="h-12 w-12 rounded-lg bg-indigo-100 flex items-center justify-center">
                      <img v-if="learningSet.icon_url" :src="learningSet.icon_url" :alt="learningSet.name" class="h-10 w-10 object-cover rounded">
                      <span v-else class="text-2xl">📘</span>
                    </div>
                  </div>
                  <div class="flex-grow">
                    <h3 class="font-medium text-gray-900 text-sm md:text-base">{{ learningSet.name }}</h3>
                    <p class="text-xs text-gray-500 mt-1">{{ learningSet.topic_name }}</p>
                    <div class="flex items-center justify-between mt-3">
                      <span class="text-xs text-gray-500">{{ $t('message.purchased') }}: {{ formatDate(learningSet.purchased_at) }}</span>
                      <a href="#" class="text-sm text-indigo-600 font-medium hover:text-indigo-800 flex items-center gap-1">
                        {{ $t('message.download') }}
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
                        </svg>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Profile Information Card -->
        <div class="bg-white rounded-2xl shadow-md overflow-hidden">
          <div class="p-6 bg-gradient-to-r from-purple-50/30 via-transparent to-transparent">
            <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <h2 class="text-lg font-semibold text-gray-900 flex items-center gap-2">
                  {{ $t('message.userProfileCaption') }} 👤
                </h2>
                <dl class="mt-4 space-y-4">
                  <div>
                    <dt class="text-sm font-medium text-gray-500">{{ $t('message.usernameCaption') }}</dt>
                    <dd class="mt-1 text-sm text-gray-900">{{ userProfile.username }}</dd>
                  </div>
                  <div>
                    <dt class="text-sm font-medium text-gray-500">{{ $t('message.emailCaption') }}</dt>
                    <dd class="mt-1 text-sm text-gray-900">{{ userProfile.email }}</dd>
                  </div>
                  <div>
                    <dt class="text-sm font-medium text-gray-500">{{ $t('message.locationCaption') }}</dt>
                    <dd class="mt-1 text-sm text-gray-900">{{ userProfile.location || $t('message.notSpecified') }}</dd>
                  </div>
                </dl>
              </div>
              <div>
                <h2 class="text-lg font-semibold text-gray-900 flex items-center gap-2">
                  {{ $t('message.bioCaption') }} 📝
                </h2>
                <p class="mt-4 text-sm text-gray-600">
                  {{ userProfile.bio || $t('message.noBio') }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted } from 'vue'
import { useAuthStore } from '@/stores/user'
import axiosInstance from '@/axios/axios'

export default {
  name: 'UserProfile',
  setup() {
    const userStore = useAuthStore()
    const isLoading = ref(true)
    const errors = ref([])
    const userProfile = ref({
      username: '',
      email: '',
      first_name: '',
      last_name: '',
      bio: '',
      location: '',
      joined_date: '',
      avatar: '',
      subscription_status: null,
      subscription_details: null,
      mandala_drawings: {
        allowed: 0,
        used: 0,
        remaining: 0,
        reset_date: null
      },
      purchased_learning_sets: []
    })

    const getStatusClass = computed(() => {
      if (!userProfile.value.subscription_details) return ''

      const statusClasses = {
        'trial': 'bg-blue-100 text-blue-800',
        'active': 'bg-green-100 text-green-800',
        'pending': 'bg-yellow-100 text-yellow-800',
        'canceled': 'bg-red-100 text-red-800',
        'expired': 'bg-gray-100 text-gray-800'
      }

      return statusClasses[userProfile.value.subscription_details.status] || 'bg-gray-100 text-gray-800'
    })

    // Check if user has purchased learning sets
    const hasPurchasedLearningSets = computed(() => {
      return userProfile.value.purchased_learning_sets &&
             userProfile.value.purchased_learning_sets.length > 0
    })

    // Calculate mandala drawings progress percentage
    const calculateDrawingsProgress = () => {
      if (!userProfile.value.mandala_drawings) return 0
      const { used, allowed } = userProfile.value.mandala_drawings
      if (!allowed || allowed === 0) return 0
      return Math.min(100, (used / allowed) * 100)
    }

    const isDrawingLimitReached = computed(() => {
      if (!userProfile.value.mandala_drawings) return false
      return userProfile.value.mandala_drawings.remaining <= 0
    })

    const isDrawingLimitLow = computed(() => {
      if (!userProfile.value.mandala_drawings) return false
      const { remaining, allowed } = userProfile.value.mandala_drawings
      return remaining > 0 && remaining < allowed * 0.2 // Less than 20% remaining
    })

    const formatDate = (date) => {
      if (!date) return ''
      return new Date(date).toLocaleDateString()
    }

    const fetchUserProfile = async () => {
      try {
        isLoading.value = true
        const response = await axiosInstance.get('/api/auth/userinfo/')
        userProfile.value = response.data
        userStore.setUser(response.data)
      } catch (error) {
        console.error('Error fetching user profile:', error)
        errors.value = [error.response?.data?.detail || 'Failed to load user profile']
      } finally {
        isLoading.value = false
      }
    }

    onMounted(() => {
      fetchUserProfile()
    })

    return {
      isLoading,
      errors,
      userProfile,
      getStatusClass,
      hasPurchasedLearningSets,
      formatDate,
      calculateDrawingsProgress,
      isDrawingLimitReached,
      isDrawingLimitLow
    }
  }
}
</script>
